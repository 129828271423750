<template>
  <section class="flex flex-col">
    <div class="relative w-full">
      <h1
        class="dark:text-neutral-light-2 text-2xl print:text-black print:dark:text-black"
        v-html="ct('THANKYOU_H1', null, t('title'))"
      />
    </div>

    <Divider class="print:hidden" />

    <span
      class="mt-2 print:hidden"
      v-html="
        ct(
          'THANKYOU_SUBHEAD',
          { email: order.contact.email, contactSupportLink },
          emailSendText
        )
      "
    />
  </section>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
  showPrintText?: boolean
}

const { order, showPrintText = true } = defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()

const contactSupportLink = `<a class="underline" href="${t('contactPath')}" target="${LINK_TARGET.BLANK}">${t('support')}</a>`

const emailSendText = computed(() =>
  order?.type === 'CARDCHARGING'
    ? t('emailSendTextCardCharging', {
        email: order.contact.email,
        contactSupportLink,
      })
    : t('emailSendText', { email: order.contact.email, contactSupportLink })
)
defineOptions({
  name: 'OrderConfirmationHeading',
})
</script>

<i18n>
de:
  title: "Vielen Dank! Die Bestellung wurde erfolgreich abgeschlossen!"
  emailSendText: "Eine E-Mail wurde an {email} versendet. Sollte die E-Mail innerhalb von 10 Minuten nicht eintreffen, empfiehlt es sich, den Spam-Ordner zu überprüfen oder den {contactSupportLink} zu kontaktieren. Alternativ lässt sich auch diese Seite ausdrucken oder die Barcodes abfotografieren (bitte in guter Qualität und ohne Verwacklungen), um sie im Kino vorzuzeigen."
  emailSendTextCardCharging: "Eine Email wurde an {email} versendet."
  contactPath: "/kontakt"
  support: "kinoheld Support"
es:
  title: "Muchas gracias por confiar en nosotros para hacer tu compra, esta es tu entrada. ¡Disfruta de la película!"
  emailSendText: "Para pasar al cine, puedes imprimir esta entrada o mostrar directamente el código de barras/QR que aparece a continuación. Además, recibirás un correo electrónico en {email} que también puedes utilizar como entrada."
  contactPath: "/contacto"
  support: "soporte de entradas"
</i18n>
