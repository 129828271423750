<template>
  <div>
    <OrderConfirmationHeading :order />
    <OrderConfirmationCodes :order />
    <OrderConfirmationOrderOverviewTicket :order :purchase-data />
    <OrderConfirmationCancellation v-if="!order.affiliate" :order />
    <OrderConfirmationCustomNotes />

    <div
      class="my-12 font-bold print:!text-black print:dark:!text-black"
      v-html="ct('THANKYOU_H3', null, '')"
    />

    <NuxtLink
      class="flex flex-row items-center underline print:hidden"
      :to="
        localeRoute({
          name: ROUTE_NAMES.CINEMA_PROGRAM,
          params: {
            citySlug: order.cinema?.city?.urlSlug,
            cinemaSlug: order.cinema?.urlSlug,
          },
        }) as RouteLocationRaw
      "
    >
      <InlineSvg :name="SVG_ICON.CHEVRON_LEFT" class="size-5" />
      <span v-text="t('backHome', { cinema: order.cinema?.name })" />
    </NuxtLink>

    <RoktWidget
      v-if="order.roktIntegration"
      :attributes="order.roktIntegration"
    />
  </div>
</template>

<script setup lang="ts">
import { type RouteLocationRaw } from 'vue-router'
import type { PurchaseData } from '@base/types/order'
import type { Order } from '#gql/default'
import { SVG_ICON } from '@assets/constants/inline-svg'
import { ROUTE_NAMES } from '@portal/constants/routes'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()
const localeRoute = useLocaleRoute()

defineOptions({
  name: 'OrderConfirmationTypeTicket',
})
</script>

<i18n>
de:
  backHome: 'Zurück zur Auswahl von {cinema}'
es:
  backHome: 'Volver a la selección desde {cinema}'
</i18n>
