<template>
  <div>
    <OrderConfirmationHeading :order />
    <OrderConfirmationOrderOverviewVoucher :order :purchase-data />
    <p class="font-bold print:text-black" v-text="t('haveFunAtNextVisit')" />
  </div>
</template>

<script setup lang="ts">
import type { Ga4Product } from '@base/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: {
    id: string
    affiliation: string
    revenue: number
    coupon: string
    items: Ga4Product[]
  }
}

const { order, purchaseData } = defineProps<Props>()

const { t } = useI18n()

defineOptions({
  name: 'OrderConfirmationTypeVoucher',
})
</script>

<i18n>
de:
  haveFunAtNextVisit: "Viel Spaß beim nächsten Kinobesuch."
es:
  haveFunAtNextVisit: "¡Disfruta de tu próxima visita al cine!"
</i18n>
