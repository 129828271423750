import extractColorValue from '@theme/utils/extract-color-value'
import getBackgroundStyles from '@theme/utils/get-background-styles'
import getImage from '@base/utils/get-image'

import type { ImageOptions } from '@nuxt/image'
import type { NuxtApp } from '#app'
import type { Design } from '#gql/default'

export interface DesignState extends Design {
  id: string
  cssVars?: string
  customCss?: string
  isDarkTheme: boolean
  background?: Design['background']
  fontStack?: Design['fontStack']
}

export default function useDesign() {
  const design = useState<DesignState>('design', () => ({
    id: '',
    cssVars: undefined,
    customCss: undefined,
    isDarkTheme: false,
    background: undefined,
    fontStack: undefined,
  }))

  const { $img } = useNuxtApp() as NuxtApp & {
    $img: (src: string, options: ImageOptions) => string
  }

  const themeColor = computed(() =>
    design.value?.cssVars
      ? `rgb(${extractColorValue(design.value.cssVars, '--color-header')})`
      : '#000'
  )

  const imgUrl = computed(() => {
    if (!design.value?.background) {
      return ''
    }

    const image = getImage(design.value, 'background.image', '')

    if (!image.src) {
      return ''
    }

    return $img(image.src, {
      width: image.width,
      height: image.height,
      quality: design.value.background.quality ?? 75,
      format: 'webp',
      blur: design.value.background.blur ?? 0,
    })
  })

  function applyDesign() {
    if (!design.value) {
      return
    }

    const headConfig = {
      meta: [
        {
          key: 'theme-color',
          name: 'theme-color',
          content: themeColor.value,
        },
      ],
      style: [
        {
          key: 'css-vars',
          innerHTML: `
            /* designId: ${design.value.id} */
            :root {
              ${design.value.cssVars ?? ''}
              --body-background-image-url: url(${imgUrl.value});
            }
          `.trim(),
        },
        {
          key: 'custom-css',
          innerHTML: `
            /* designId: ${design.value.id} */
            ${design.value.customCss ?? ''}
          `.trim(),
        },
      ],
      bodyAttrs: {
        tagDuplicateStrategy: 'replace',
        class: [
          'bg-body',
          design.value.isDarkTheme && 'dark',
          `font-${design.value.fontStack}`,
          design.value.background &&
            'lg:bg-[image:var(--body-background-image-url)]',
        ]
          .filter(Boolean)
          .join(' '),
        style: getBackgroundStyles(design.value.background),
      },
    }

    useHead({
      meta: headConfig.meta,
      style: headConfig.style,
      bodyAttrs: {
        class: headConfig.bodyAttrs.class,
        style: headConfig.bodyAttrs.style,
        tagDuplicateStrategy: 'replace' as const,
      },
    })
  }

  return {
    design,
    applyDesign,
  }
}
