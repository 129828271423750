export const PAYMENT_METHOD = {
  CUSTOMER_CARD: 'CUSTOMER_CARD',
  ZEROPAYMENT: 'ZEROPAYMENT',
  PAYPAL: 'PAYPAL',
  HEIDELPAY: 'HEIDELPAY',
  SOFORT_UEBERWEISUNG: 'SOFORT_UEBERWEISUNG',
  CREDIT_CARD: 'CREDIT_CARD',
  CREDIT_CARD_KPS: 'CREDIT_CARD_KPS',
  MANUAL: 'MANUAL',
  MASTERPASS_KPS: 'MASTERPASS_KPS',
  AMAZON: 'AMAZON',
  AMAZON_V2: 'AMAZON_V2',
  KLARNA: 'KLARNA',
  TERMINAL: 'TERMINAL',
  BIZUM: 'BIZUM',
  GOOGLE_PAY: 'GOOGLE_PAY',
  KPS: 'KPS',
}
