<template>
  <Button
    class="w-auto print:hidden"
    :text="t('print')"
    :icon="SVG_ICON.PRINTER"
    :truncate="false"
    :size="BUTTON_SIZE.SM"
    @click="print"
  />
</template>

<script setup lang="ts">
const { t } = useI18n()

function print() {
  window.print()
}

defineOptions({
  name: 'PrintButton',
})
</script>

<i18n>
de:
  print: "Drucken"
es:
  print: "Imprimir"
</i18n>
