<template>
  <NuxtImg class="min-h-12 print:hidden" :src="googleAddToWalletIconLink" />
</template>

<script setup lang="ts">
const { locale } = useI18n()

const googleAddToWalletIconLink = computed(
  () =>
    `/images/google/add-to-wallet-png/${locale.value}_add_to_google_wallet_add-wallet-badge.png`
)

defineOptions({
  name: 'IconGoogleWallet',
})
</script>
