<template>
  <div id="terms-and-conditions" ref="widgetRef" />
  <input id="evi-user-email" type="text" :value="inputValue" class="hidden" />
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  url: string
  token: string
  order: Order
}

const { url, token, order } = defineProps<Props>()
const widgetRef = ref<HTMLElement | null>(null)

const inputValue = computed(() => {
  return order.contact?.email
})

interface EviWidgetInstance {
  save: () => void
}

interface EviWidgetConstructor {
  (options: {
    baseURL: string
    token: string
    error: () => void
    mailInputSelector: string
  }): EviWidgetInstance
}

const isWidgetInitialized = ref(false)

onMounted(() => {
  useScriptTag(
    url,
    () => {
      initializeWidget()
    },
    {
      async: true,
    }
  )
})

const initializeWidget = () => {
  if (!widgetRef.value || isWidgetInitialized.value) return

  try {
    const createEviWidget = (window as any)
      .createEviWidget as EviWidgetConstructor
    if (typeof createEviWidget !== 'function') {
      console.error('EVI Widget constructor not found')
      return
    }

    // Initialize the widget
    const widget = createEviWidget({
      baseURL: url,
      token,
      error: () => console.error('Error occurred at createEviWidget'),
      mailInputSelector: '#evi-user-email',
    })

    // Handle widget ready event
    widgetRef.value.addEventListener('evi-widget:ready', () => {
      if (widget?.save) {
        widget.save()
      }
    })

    isWidgetInitialized.value = true
  } catch (error) {
    console.error('EVI Widget initialization failed:', error)
  }
}

defineOptions({
  name: 'EviWidget',
})
</script>
