<template>
  <div class="flex flex-col space-y-4">
    <OrderConfirmationHeading :order />
    <OrderConfirmationOrderOverviewSubscription :order :purchase-data />
    <p class="font-bold print:text-black" v-text="ct('THANKYOU_H3', null, t('haveFunText'))" />
  </div>
</template>

<script setup lang="ts">
import type { PurchaseData } from '@base/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()

defineOptions({
  name: 'OrderConfirmationTypeSubscription',
})
</script>

<i18n>
de:
  haveFunText: "Viel Spaß beim nächsten Kinobesuch."
es:
  haveFunText: "¡Disfruta de tu próxima visita al cine!"
</i18n>
