<template>
  <div
    v-if="note"
    class="print:!text-black print:dark:!text-black"
    v-html="note"
  />
</template>

<script setup lang="ts">
interface Props {
  ctKey: string
}

const { ctKey } = defineProps<Props>()

const { ct } = useCinemaStrings()

const note = computed(() => {
  const PLACEHOLDER = 'placeholder'
  const temp = ct(ctKey, null, PLACEHOLDER)

  return temp !== PLACEHOLDER ? temp : null
})

defineOptions({
  name: 'OrderConfirmationCustomNote',
})
</script>
