export const FLAG_ICONS = {
  DE: 'de',
  EN: 'en',
  ES: 'es',
} as const

export const PAYMENT_ICONS = {
  AMAZON_DARK: 'amazon_dark',
  AMAZON_LIGHT: 'amazon_light',
  CREDITCARD: 'creditcard',
  GPAY_DARK: 'gpay_dark',
  GPAY_LIGHT: 'gpay_light',
  KLARNA: 'klarna',
  LOYALTY: 'loyalty',
  PAYPAL_DARK: 'paypal_dark',
  PAYPAL_LIGHT: 'paypal_light',
  UNZER: 'unzer',
} as const

export const PORTAL_LOGO = {
  ENTRADAS: 'entradas',
  KINOHELD: 'kinoheld',
  POWERED_BY_KINOHELD: 'powered-by-kinoheld',
} as const

export const LOGO = {
  FILMDIENST: 'filmdienst',
} as const

export const SVG_ICON = {
  APPLE_WALLET_ADD_DE: 'apple-wallet-add-de',
  APPLE_WALLET_ADD_EN: 'apple-wallet-add-en',
  APPLE_WALLET_ADD_ES: 'apple-wallet-add-es',
  ACCOUNT: 'account',
  ACCOUNT_CIRCLE_OUTLINE: 'account-circle-outline',
  ALERT_CIRCLE_OUTLINE: 'alert-circle-outline',
  BOOKMARK_FILLED: 'bookmark-filled',
  BOOKMARK_OUTLINE: 'bookmark-outline',
  CALENDAR: 'calendar',
  CART_OUTLINE: 'cart-outline',
  COG_OUTLINE: 'cog-outline',
  CHECK: 'check',
  CHECK_CIRCLE: 'check-circle',
  CHECK_CIRCLE_OUTLINE: 'check-circle-outline',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_LEFT: 'chevron-left',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_UP: 'chevron-up',
  CIRCLE: 'circle',
  CIRCLE_MEDIUM: 'circle-medium',
  EYE_OUTLINE: 'eye-outline',
  CLOSE: 'close',
  CROSSHAIRS: 'crosshairs',
  QUESTION_MARK: 'question-mark',
  CROSSHAIRS_GPS: 'crosshairs-gps',
  EMAIL: 'email',
  EXCLAMATION: 'exclamation',
  EXPRESS_TICKET: 'express-ticket',
  FACEBOOK: 'facebook',
  FILTER: 'filter',
  LOADING: 'loading',
  LOADING_CIRCLE: 'loading-circle',
  FOOD_FORK_DRINK: 'food-fork-drink',
  GALLERY_IMAGE: 'gallery-image',
  GALLERY_TRAILER: 'gallery-trailer',
  GOOGLE_WALLET_ADD_BADGE_DE: 'google-wallet-add-badge-de',
  GOOGLE_WALLET_ADD_BADGE_EN: 'google-wallet-add-badge-en',
  GOOGLE_WALLET_ADD_BADGE_ES: 'google-wallet-add-badge-es',
  HOME: 'home',
  INFORMATION_OUTLINE: 'information-outline',
  LINKEDIN: 'linkedin',
  INSTAGRAM: 'instagram',
  INSTAGRAM_OUTLINE: 'instagram-outline',
  MAGNIFY: 'magnify',
  MAIL: 'mail',
  MAIL_SENT: 'mail-sent',
  MAP_MARKER: 'map-marker',
  MAP_MARKER_OUTLINE: 'map-marker-outline',
  MENU: 'menu',
  MINUS: 'minus',
  MOVIE_ROLL: 'movie-roll',
  OPEN_IN_NEW: 'open-in-new',
  PALETTE: 'palette',
  PHONE: 'phone',
  PINTEREST: 'pinterest',
  PLAY_TRAILER: 'play-trailer',
  PAUSE_CIRCLE_OUTLINE: 'pause-circle-outline',
  PLAY: 'play',
  PLAY_CIRCLE_OUTLINE: 'play-circle-outline',
  PLUS: 'plus',
  POPCORN: 'popcorn',
  PRINTER: 'printer',
  SEAT: 'seat',
  SHIMMER: 'shimmer',
  SHOPPING_OUTLINE: 'shopping-outline',
  SQUARE_EDIT_OUTLINE: 'square-edit-outline',
  STAR: 'star',
  EMPTY_CART: 'empty-cart',
  STAR_OUTLINE: 'star-outline',
  THEATER: 'theater',
  TICKET_CONFIRMATION: 'ticket-confirmation',
  TICKET_CONFIRMATION_OUTLINE: 'ticket-confirmation-outline',
  TIKTOK: 'tiktok',
  TUMBLR: 'tumblr',
  TRASH_CAN_OUTLINE: 'trash-can-outline',
  TWITTER: 'twitter',
  WHATSAPP: 'whatsapp',
  WEB: 'web',
  YOUTUBE: 'youtube',
  X: 'x',
} as const

export const INLINE_SVG_TYPE = {
  ICON: 'icon',
  LOGO: 'logo',
  FLAG: 'flag',
  PAYMENT_ICON: 'payment-icon',
  ILLUSTRATION: 'illustration',
} as const

export const ILLUSTRATION = {
  POPCORN: 'popcorn',
} as const
