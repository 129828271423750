<template>
  <OrderConfirmationOrderOverviewWrapper
    :order
    :show-presale-disclaimer="false"
  >
    <div class="grid grid-cols-3 py-2">
      <span v-text="t('cinema')" />
      <span class="col-span-2" v-text="cinemaString" />

      <span v-text="t('orderNumber')" />
      <span class="col-span-2" v-text="order.orderNumber" />

      <span v-text="t('cardNumber')" />
      <span class="col-span-2" v-text="order.cardChargings[0].cardNumber" />

      <span v-text="t('charge')" />
      <span
        class="col-span-2"
        v-text="n(Number(purchaseData.items[0].price.toFixed(2)), 'currency')"
      />
    </div>
  </OrderConfirmationOrderOverviewWrapper>
</template>

<script setup lang="ts">
import type { PurchaseData } from '@base/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

const { order, purchaseData } = defineProps<Props>()

const { t, n } = useI18n()

const cinemaString = formatCinemaAddressFromOrder(order)

defineOptions({
  name: 'OrderConfirmationOrderOverviewCustomerCard',
})
</script>

<i18n>
de:
  cinema: "Kino"
  orderNumber: "Bestellnummer"
  cardNumber: "Kartennummer"
  charge: "Aufladung"
es:
  cinema: "Cine"
  orderNumber: "Número de pedido"
  cardNumber: "Número de tarjeta"
  charge: "Carga"
</i18n>
