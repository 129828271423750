<template>
  <OrderConfirmationOrderOverviewWrapper
    :order
    :show-presale-disclaimer="false"
  >
    <div class="grid grid-cols-3 py-2">
      <span v-text="t('cinema')" />
      <span class="col-span-2" v-text="cinemaString" />

      <span v-text="t('orderNumber')" />
      <span class="col-span-2" v-text="order.orderNumber" />

      <span v-text="t('orderNumber')" />
      <span class="col-span-2" v-text="order.orderNumber" />
    </div>
  </OrderConfirmationOrderOverviewWrapper>
</template>

<script setup lang="ts">
import type { PurchaseData } from '@base/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

const { order } = defineProps<Props>()

const { t } = useI18n()

const cinemaString = formatCinemaAddressFromOrder(order)

defineOptions({
  name: 'OrderConfirmationOrderOverviewSubscription',
})
</script>

<i18n>
de:
  cinema: "Kino"
  orderNumber: "Bestellnummer"
  vouchers: "Gutscheine"
es:
  cinema: "Cine"
  orderNumber: "Número de pedido"
  vouchers: "Vales"
</i18n>
