<template>
  <section class="mt-11 flex flex-col">
    <div v-if="order.codes?.length" class="flex flex-col">
      <PageHeading
        :tag="PAGE_HEADING_TAG.H3"
        :title="
          order.overview?.headline ??
          t('ticketCodeText', { code: order.codes[0].code })
        "
      />

      <Divider />

      <div class="my-2 flex flex-col space-y-2">
        <div
          class="flex flex-col items-start space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0"
        >
          <NuxtImg
            v-for="(code, index) in order.codes"
            :key="index"
            :src="code.image"
            class="h-24 bg-white p-2"
          />
        </div>

        <div class="flex flex-col items-start space-y-1.5 print:hidden">
          <NuxtLink
            v-if="order.walletLinks?.pkpass"
            :to="order.walletLinks.pkpass"
            :aria-label="t('linkToAppleWallet')"
          >
            <IconAppleWallet />
          </NuxtLink>
          <NuxtLink
            v-if="order.walletLinks?.gpass"
            :to="order.walletLinks.gpass"
            :aria-label="t('linkToGoogleWallet')"
          >
            <IconGoogleWallet />
          </NuxtLink>
        </div>
      </div>
    </div>

    <div>
      <PageHeading
        :tag="PAGE_HEADING_TAG.H3"
        :title="
          order.overview?.headline ??
          t('ticketCodeText', { code: order.codes[0].code })
        "
      />

      <Divider />

      <div v-for="(n, i) in Array(shows).fill(0)" :key="`show-${n}-${i}`">
        <span
          v-if="shows > 1"
          class="text-lg font-bold print:text-black print:dark:text-black"
          v-text="`${t('show')} #${i + 1} `"
        />
        <div
          class="grid grid-cols-1 gap-8 py-3 md:grid-cols-2 md:gap-4 print:grid-cols-3"
          :class="{
            'lg:grid-cols-3': ticketsByShow[i]?.length > 2,
          }"
        >
          <OrderConfirmationCodesTicket
            v-for="(ticket, index) in ticketsByShow[i]"
            :key="`ticket-${index}`"
            :ticket
            :overview="order.overview"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'
import { PAGE_HEADING_TAG } from '@base/constants/page'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()

const shows = computed(() => {
  const tickets = order.tickets

  if (!tickets?.length) {
    return 0
  }

  const seatMap = new Map<string, number>()

  tickets.forEach((ticket) => {
    const key = `${ticket?.seat?.row}-${ticket?.seat?.number}`
    seatMap.set(key, (seatMap.get(key) || 0) + 1)
  })

  return Math.max(...Array.from(seatMap.values()))
})

const ticketsByShow = computed(() => {
  const tickets = order.tickets

  if (!tickets?.length) {
    return []
  }

  if (shows.value === 1) {
    return [tickets]
  }

  const uniqueSeats = new Set(
    tickets.map(
      (ticket: Order['tickets']) =>
        `${ticket?.seat?.row}-${ticket?.seat?.number}`
    )
  )

  return Array.from({ length: shows.value }, (_, showIndex) =>
    tickets.filter(
      (_: Order['tickets'][number], ticketIndex: number) =>
        Math.floor(ticketIndex / uniqueSeats.size) === showIndex
    )
  )
})

defineOptions({
  name: 'OrderConfirmationCodes',
})
</script>

<i18n>
de:
  linkToAppleWallet: "Link zur Apple Wallet"
  linkToGoogleWallet: "Link zur Google Wallet"
  ticketCodeText: "Dein Code für die Tickets: {code}"
  yourTickets: "Deine Tickets"
  yourTicketCode: "Dein Ticket-Code"
  show: "Vorstellung"
es:
  linkToAppleWallet: "Link a la Apple Wallet"
  linkToGoogleWallet: "Link a la Google Wallet"
  ticketCodeText: "Tu código para las entradas: {code}"
  yourTickets: "Tus entradas"
  yourTicketCode: "Tu código de ticket"
  show: "Función"
</i18n>

<style>
.st0 {
  fill: #a6a6a6;
}
.st1 {
  clip-path: url(#SVGID_00000036241015773612366540000011328654630077282433_);
}
.st2 {
  fill: #dedbce;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #40a5d9;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffb003;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #40c740;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f26d5f;
}
.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #d9d6cc;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #dedbce;
}
.st9 {
  fill: #ffffff;
}
</style>
