import { useCookies } from '@vueuse/integrations/useCookies'
import useCms from '@cms/composables/use-cms'
import type { CmsConsentPurpose, CmsConsentVendor } from '#gql/default'

export default function useConsentManager() {
  const { config } = useCms()
  const userConsent = useConsentState()
  const { currentHost, currentDomain, currentUrl } = useCurrentUrl()
  const modalState = useConsentModalState()
  const cookies = useCookies([CONSENT_MANAGER_COOKIE_NAME])

  function getVendors({
    category,
    pick,
  }: {
    category?: string
    pick?: 'id' | 'name' | 'domains'
  } = {}) {
    return config.value?.consentSettings.purposes
      .filter((purpose: CmsConsentPurpose) =>
        category ? purpose.category === category : true
      )
      .flatMap((purpose: CmsConsentPurpose) =>
        purpose.vendors.map((vendor: CmsConsentVendor) =>
          pick
            ? (vendor[pick] as string | string[])
            : (vendor as CmsConsentVendor)
        )
      )
  }

  function getVendorForDomain({
    domain,
    pick,
  }: {
    domain: string
    pick?: 'id' | 'name' | 'domains'
  }) {
    const vendors = getVendors() as CmsConsentVendor[]
    const vendor = vendors?.find(({ domains }) => domains?.includes(domain))

    if (!vendor) {
      return null
    }

    return pick ? vendor[pick] : vendor
  }

  function getConsentForDomain(domain: string) {
    const vendorKey = getVendorForDomain({ domain, pick: 'id' })
    return vendorKey ? getConsentForVendor(vendorKey as string) : false
  }

  function getRequiredVendorKeys() {
    return getVendors({
      category: CONSENT_MANAGER_PURPOSE.REQUIRED,
      pick: 'id',
    }) as string[]
  }

  function getConsentForVendor(vendorKey: string): boolean {
    const requiredKeys = getRequiredVendorKeys()
    return (
      requiredKeys.includes(vendorKey) ||
      !!(userConsent.value?.includes(vendorKey) ?? false)
    )
  }

  function getVendorPurpose(vendorKey: string) {
    return config.value?.consentSettings.purposes.find((purpose) =>
      purpose.vendors.some((vendor) => vendor.id === vendorKey)
    )
  }

  function getConsentForPurpose(purposeKey: string): boolean {
    const requiredKeys = getRequiredVendorKeys()
    return !!getVendors({ category: purposeKey, pick: 'id' })?.every(
      (vendorKey) =>
        requiredKeys.includes(vendorKey as string) ||
        getConsentForVendor(vendorKey as string)
    )
  }

  function setConsentForPurpose(purposeKey: string, consentValue: boolean) {
    getVendors({ category: purposeKey, pick: 'id' })?.forEach((vendorKey) =>
      setConsentForVendor(vendorKey as string, consentValue)
    )
  }

  function setConsentForVendor(vendorKey: string, consentValue: boolean) {
    let vendorKeys = userConsent.value ?? []

    if (consentValue) {
      vendorKeys.push(vendorKey)
      vendorKeys = Array.from(new Set(vendorKeys)) // remove duplicates
    } else {
      vendorKeys = vendorKeys.filter((key: string) => key !== vendorKey)
    }
    userConsent.value = vendorKeys
    updateCookie()
  }

  function setConsent(vendorKeys: string[]) {
    userConsent.value = vendorKeys
    updateCookie()
  }

  function acceptAll() {
    userConsent.value = [...(getVendors({ pick: 'id' }) as string[])]
    updateCookie()
  }

  // Resets the cookie value to only the required vendorKeys
  function declineAll() {
    userConsent.value = [
      ...(getVendors({
        category: CONSENT_MANAGER_PURPOSE.REQUIRED,
        pick: 'id',
      }) as string[]),
    ]
    updateCookie()
  }

  function updateCookie() {
    if (!userConsent.value) {
      return
    }

    const today = new Date()
    const nextYear = new Date()
    nextYear.setFullYear(today.getFullYear() + 1)

    cookies.set(CONSENT_MANAGER_COOKIE_NAME, userConsent.value.join(','), {
      expires: nextYear,
      path: '/',
    })
  }

  const allDomains = computed(() => {
    const vendors = getVendors({ pick: 'domains' }) as string[][]
    return vendors.flat()
  })
  const allowedDomains = computed(() => {
    const requiredKeys = getVendors({
      category: CONSENT_MANAGER_PURPOSE.REQUIRED,
      pick: 'id',
    }) as string[]

    return [
      ...(getVendors() as CmsConsentVendor[])
        .filter(
          ({ id }) =>
            requiredKeys.includes(id) || userConsent.value?.includes(id)
        )
        .flatMap(({ domains }) => domains),
      currentDomain.value,
    ]
  })

  const consentCookieValue = computed(() =>
    cookies.get(CONSENT_MANAGER_COOKIE_NAME)
  )

  watch(consentCookieValue, () => {
    if (!consentCookieValue.value && import.meta.client) {
      userConsent.value = undefined
      toggleModal({ settings: false, initial: true })
    }
  })

  function toggleModal({
    settings,
    initial,
  }: {
    settings: boolean
    initial: boolean
  }) {
    modalState.value = {
      isSettingsModalVisible: settings,
      isInitialModalVisible: initial,
    }
  }

  function isUrlAllowed(value: string): boolean {
    try {
      const url = new URL(value, currentHost.value)

      if (url.protocol === ':') {
        return false
      }

      const isAllowed = allowedDomains.value.includes(url.hostname)
      if (isAllowed) {
        return true
      }

      useAsyncGql({
        operation: 'LogMissingConsent',
        variables: { pageUrl: currentUrl, resourceUrl: url.toString() },
      })

      return false
    } catch {
      return false
    }
  }

  function handleElementCreation(
    tagName: string,
    element: HTMLElement
  ): HTMLElement {
    const originalSetAttribute = element.setAttribute.bind(element)

    element.setAttribute = function (
      attributeName: string,
      attributeValue: string
    ): void {
      if (
        (tagName === 'img' || tagName === 'script' || tagName === 'iframe') &&
        attributeName === 'src'
      ) {
        if (!isUrlAllowed(attributeValue)) {
          element.dataset.blockedSrc = attributeValue
          return
        }
      }

      if (tagName === 'link' && attributeName === 'href') {
        if (!isUrlAllowed(attributeValue)) {
          element.dataset.blockedHref = attributeValue
          return
        }
      }

      originalSetAttribute(attributeName, attributeValue)
    }

    return element
  }

  function overrideCreateElement(): void {
    const originalCreateElement = document.createElement.bind(document)

    document.createElement = function (tagName: string): HTMLElement {
      const element = originalCreateElement(tagName)

      if (['script', 'iframe', 'link', 'img'].includes(tagName)) {
        return handleElementCreation(tagName, element)
      }
      return element
    }
  }

  const isInitialModalVisible = computed(
    () => modalState.value.isInitialModalVisible
  )
  const isSettingsModalVisible = computed(
    () => modalState.value.isSettingsModalVisible
  )
  const consentMode = computed(() => config.value?.consentSettings.mode)

  return {
    userConsent,
    setConsent,
    acceptAll,
    declineAll,
    allowedDomains,
    allDomains,
    setConsentForVendor,
    getVendors,
    getVendorForDomain,
    getConsentForVendor,
    getVendorPurpose,
    getConsentForDomain,
    getRequiredVendorKeys,
    modalState,
    toggleModal,
    getConsentForPurpose,
    setConsentForPurpose,
    overrideCreateElement,
    isInitialModalVisible,
    isSettingsModalVisible,
    consentMode,
  }
}
