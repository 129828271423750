import type { DesignState } from '@theme/composables/use-design'
import type { PurchaseData, Ga4Product } from '@base/types/order'
import type {
  OrderConcession,
  OrderVoucher,
  OrderCardCharging,
  OrderSubscription,
  OrderTicket,
  Cinema,
  Price,
  Show,
  Order,
} from '#gql/default'

type GroupedItems = Record<string, Ga4Product>

function incrementOrInitializeProduct(
  grouped: GroupedItems,
  id: string,
  product: Ga4Product
) {
  if (!grouped[id]) {
    grouped[id] = { ...product, quantity: 0 }
  }
  grouped[id].quantity += 1
}

function generateGenericConcession({
  id,
  name,
  price,
  quantity,
}: {
  id: string
  name: string
  price: Price
  quantity: number
}): OrderConcession {
  return {
    quantity,
    concession: {
      id,
      name,
      description: '',
      price,
      subItems: [],
    },
    price,
  }
}

function mapTicketsToItems(tickets: OrderTicket[], show: Show, cinema: Cinema) {
  const grouped: GroupedItems = {}
  const defaultPriceCategory = {
    id: 'ticket',
    name: 'ticket',
  }

  tickets.forEach((ticket) => {
    const { id, name } = ticket.priceCategory ?? defaultPriceCategory
    const product: Ga4Product = {
      itemId: show.movie?.id ?? '',
      itemName: show.movie?.title ?? show.name,
      affiliation: ORDER_TYPE.TICKET,
      discount: '',
      itemBrand: cinema.name,
      itemCategory: 'Movie',
      itemCategory2: show.movie?.contentRating?.minimumAge?.toString(),
      itemCategory3: show.movie?.genres?.[0]?.name ?? '',
      itemCategory4: show.beginning.substring(0, 10),
      itemVariant: name!,
      price: ticket.originalPrice.total,
      quantity: 1,
    }
    incrementOrInitializeProduct(grouped, id, product)
  })

  return Object.values(grouped)
}

function mapConcessionsToItems({
  affiliation,
  brand = 'Portal',
  category = 'Concession',
  concessions,
}: {
  affiliation: string
  brand?: string
  category?: string
  concessions: OrderConcession[]
}) {
  return concessions.map(({ concession, quantity }) => {
    const id = concession.id ?? ''
    const product: Ga4Product = {
      itemId: id,
      itemName: concession.name,
      affiliation,
      discount: '',
      itemBrand: brand,
      itemCategory: category,
      itemCategory2: concession.category?.name ?? '',
      itemCategory3: concession.description ?? '',
      price: concession.price.total,
      quantity,
    }

    return product
  })
}

function mapCardChargingsToItems({
  cardChargings,
  cinema,
}: {
  cardChargings: OrderCardCharging[]
  cinema: Cinema
}) {
  return cardChargings.map(({ chargeAmount, price }) => {
    const product: Ga4Product = {
      itemId: ['cc', cinema.id, 'generic', chargeAmount.value].join('-'),
      itemName: `Charge-${chargeAmount.value}`,
      affiliation: 'CardChargingShop',
      itemBrand: cinema.name,
      itemCategory: 'Card Charging',
      price: price.total,
      quantity: 1,
    }

    return product
  })
}

function mapSubscriptionsToItems(
  subscriptions: OrderSubscription[],
  cinema: Cinema
) {
  return subscriptions.map(({ name, price }) => {
    const product: Ga4Product = {
      itemId: ['sub', cinema.id, 'generic', price.total].join('-'),
      itemName: name,
      affiliation: 'SubscriptionShop',
      itemBrand: cinema.name,
      itemCategory: 'Subscription',
      price: price.total,
      quantity: 1,
    }

    return product
  })
}

function mapVouchersToItems(vouchers: OrderVoucher[], cinema?: Cinema) {
  const grouped: GroupedItems = {}

  vouchers.forEach((voucher) => {
    const id = [
      'vo',
      cinema?.id ?? 'portal',
      voucher.isGeneric
        ? `generic-${voucher.value.value}`
        : voucher.name.toLowerCase().replace(/\s/g, '-') +
          '-' +
          voucher.value.value,
    ].join('-')
    const product: Ga4Product = {
      itemId: id,
      itemName: voucher.name,
      affiliation: ORDER_TYPE.VOUCHERBUY,
      itemBrand: cinema?.name ?? 'Portal',
      itemCategory: 'Voucher',
      price: voucher.price.total,
      quantity: 1,
    }
    incrementOrInitializeProduct(grouped, id, product)
  })

  return Object.values(grouped)
}

export default async function useOrder({
  securityKey,
  orderNumber,
  design,
}: {
  securityKey: string
  orderNumber: string
  design?: string
}) {
  const order = useState<Order>('order')

  const { isWidgetMode } = useWidgetMode()
  const { design: designState, applyDesign } = useDesign()

  const { data } = await useAsyncGql('FetchOrder', {
    orderNumber,
    securityKey,
    design,
  })

  if (data.value?.order) {
    order.value = data.value.order as Order

    await useOrderCinemaStringsFetch(data.value.order as Order)

    if (isWidgetMode.value && order.value.cinema?.design) {
      designState.value = order.value.cinema!.design as unknown as DesignState
      applyDesign()
    }
  }

  const purchaseData = computed<PurchaseData | null>(() => {
    if (!order.value) {
      return null
    }

    const {
      orderNumber,
      cinema,
      price,
      type,
      show,
      concessions,
      shopItems,
      shippingFee,
      tickets,
      vouchers,
      cardChargings,
      subscriptions,
    } = order.value
    const items: Ga4Product[] = []

    if (tickets.length) {
      items.push(...mapTicketsToItems(tickets, show!, cinema!))
    }

    if (vouchers.length) {
      items.push(...mapVouchersToItems(vouchers, cinema!))
    }

    if (shopItems.length) {
      items.push(
        ...mapConcessionsToItems({
          affiliation: type!,
          brand: cinema?.name,
          concessions: shopItems,
        })
      )
    }

    if (shippingFee?.total) {
      const item = generateGenericConcession({
        id: `shipping-${cinema?.id}-generic-${shippingFee.total}`,
        name: `Shipping ${shippingFee.total}`,
        price: shippingFee,
        quantity: 1,
      })

      items.push(
        ...mapConcessionsToItems({
          affiliation: type!,
          brand: cinema?.name,
          concessions: [item],
        })
      )
    }

    if (concessions.length) {
      items.push(
        ...mapConcessionsToItems({
          affiliation: type!,
          brand: cinema?.name,
          concessions,
        })
      )
    }

    if (cardChargings.length) {
      items.push(
        ...mapCardChargingsToItems({
          cardChargings,
          cinema: cinema!,
        })
      )
    }

    if (subscriptions.length) {
      items.push(...mapSubscriptionsToItems(subscriptions, cinema!))
    }

    return {
      id: orderNumber,
      affiliation: cinema?.name ?? 'Portal',
      revenue: price.total,
      coupon: '',
      items,
    }
  })

  return {
    order,
    purchaseData,
  }
}
