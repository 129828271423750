<template>
  <OrderConfirmationCustomNote ct-key="THANKYOU_BOOKINGNOTE" />
  <OrderConfirmationCustomNote
    v-for="key in ticketOrderFlagKeys"
    :key
    :ct-key="key"
  />
  <OrderConfirmationCustomNote ct-key="MAIL_BOOKING_CUSTOMNOTICE" />
</template>

<script setup lang="ts">
const { ticketOrderFlagKeys } = useOrderCinemaStringsFetch()

defineOptions({
  name: 'OrderConfirmationCustomNotes',
})
</script>
